;(function ($, window, document, undefined) {

    "use strict";


    var pluginName = "istecDownload",
        defaults = {
            downloadsAdded: 0,
            downloadsSize: 0,
            downloads: []
        };


    // The actual plugin constructor
    function Plugin(element, options) {
        this.element = element;
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
            getcookie: function () {
                if (!!$.cookie("istecDownloads")) {
                    return JSON.parse(jQuery.cookie("istecDownloads"), { path: '/' });
                } else {
                    $.cookie("istecDownloads", JSON.stringify(this._defaults), { path: '/' });
                    //return JSON.parse(jQuery.cookie("istecDownloads"), { path: '/' });
                }

            },
            init: function () {
                this.getcookie();
                this.setDefaults();
                this.showDownloads();
                this.handleAdd();
                this.getDownloads();
            },
            setDefaults: function () {
                var count = JSON.parse(jQuery.cookie("istecDownloads"), { path: '/' }).downloads.length;
                $('.downloadcount').html(count);
                if(count > 0){
                    $("#downloadarea").removeClass("hidden");
                    $("#downloadarea").removeClass("fadeOut");
                    $("#downloadarea").addClass("fadeIn");
                }else{
                    $("#downloadarea").addClass("hidden");
                    $("#downloadarea").addClass("fadeOut");
                    $("#downloadarea").removeClass("fadeIn");
                }
                this.settings.defaults = this.getcookie();
            },
            handleAdd: function () {

                $(".add-download-to-list").on('click', function (download) {
                    var defaults = JSON.parse(jQuery.cookie("istecDownloads"), { path: '/' });
                    var id = $(this).data("download-id");
                    var name = $(this).data("download-naam");
                    var casename = $(this).data("download-type");
                    var notInList = true;
                    if (id !== undefined) {
                        var length = defaults.downloads.length;
                        for (var i = 0; i < length; i++) {
                            if (defaults.downloads[i].id == id) {
                                notInList = false;
                            }
                        }
                        if (notInList) {
                            var info = {
                                name: name,
                                id: id,
                                casename: casename
                            };
                            defaults.downloads.push(info);
                            defaults.downloadsAdded = defaults.downloads.length;
                            $.cookie("istecDownloads", JSON.stringify(defaults),  { path: '/' });
                        }
                        var count = defaults.downloads.length;
                        $('.downloadcount').html(count);
                        if(count > 0){
                            $("#downloadarea").removeClass("hidden");
                            $("#downloadarea").removeClass("fadeOut");
                            $("#downloadarea").addClass("fadeIn");
                        } else{
                            $("#downloadarea").addClass("hidden");
                            $("#downloadarea").addClass("fadeOut");
                            $("#downloadarea").removeClass("fadeIn");
                        }

                        if(count > 1) {
                            if($('.downloadcount').hasClass("bounceIn")) {
                                $('.downloadcount').removeClass("bounceIn");

                                setTimeout(function() {
                                    $('.downloadcount').addClass("bounceIn");
                                }, 500);
                            }
                            else {
                                $('.downloadcount').addClass("bounceIn");
                            }
                        }
                    }
                });

            },
            showDownloads: function () {
                var plugin = this;

                $('#downloadarea').on('click', function () {
                    var defaults = JSON.parse(jQuery.cookie("istecDownloads"),  { path: '/' });
                    var div = $("#downloadsModal").find("tbody");
                    div.html('');
                    for (var i = 0; i < defaults.downloads.length; i++) {
                        var download = defaults.downloads[i];
                        var id = download.id;
                        var tr = $("<tr/>").attr('id', 'download' + download.id);
                        tr.append($("<td/>").addClass("text-subtext").html(download.casename));
                        tr.append($("<td/>").html(download.name));
                        tr.append(
                            $("<td/>")
                                .attr('id', id)
                                .on('click', function () {
                                    plugin.removeDownload($(this));
                                })
                                .addClass("product-icons-cell text-right")
                                .append(
                                    $("<i/>")
                                        .addClass('fa fa-close product-downsloads-icon cursor-pointer')
                                        .attr('aria-hidden', true)

                                ));
                        div.append(tr);
                    }
                });
            },
            removeDownload: function (div) {
                console.log(div.html());
                var id = div.attr('id');
                var defaults = this.getcookie();
                console.log(id);
                var downloads = [];
                var length = defaults.downloads.length;
                for (var i = 0; i < length; i++) {
                    if (defaults.downloads[i].id != id) {
                        downloads.push(defaults.downloads[i]);
                    }
                }
                defaults.downloads = [];
                defaults.downloads = downloads;
                $.cookie("istecDownloads", JSON.stringify(defaults), { path: '/' });
                $('.downloadcount').html(downloads.length);
                $('#download' + id).remove();
            },
            getDownloads: function () {
                $('.download_zip').on('click', function () {
                    var email = $("#downloadmailto").val();
                    if(!email){
                        $(".wrongmail").removeClass('hidden');
                        return;
                    }

                    var defaults = JSON.parse(jQuery.cookie("istecDownloads"), { path: '/' });
                    var downloads = [];
                    var ids = '';
                    var length = defaults.downloads.length;
                    if(length == 0){
                        alert('geen downloads');
                        return;
                    }
                    for (var i = 0; i < length; i++) {
                            ids += defaults.downloads[i].id + ',';
                    }

                    $.get( ISTEC.siteurl + "/download/informatie.php?email="+ email + "&q=" + ids, function( data ) {
                        alert('Downloads zijn verzonden: ' + data);
                    });
                });
            },
            validateEmail: function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }
    );

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" +
                    pluginName, new Plugin(this, options));
            }
        });
    };

})(jQuery, window, document);